import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = _createElementVNode("div", { class: "fw-semibold fs-5 mt-3 mb-1 mx-5 text-center" }, " All Reports ", -1);
const _hoisted_2 = { class: "report-list-table mb-5" };
const _hoisted_3 = { class: "table table-horizontal" };
const _hoisted_4 = _createElementVNode("th", { class: "col-1" }, null, -1);
const _hoisted_5 = _createTextVNode(" Display Name ");
const _hoisted_6 = {
    key: 0,
    class: "bi",
    width: "20",
    height: "20",
    fill: "#A0202E"
};
const _hoisted_7 = ["xlink:href"];
const _hoisted_8 = _createTextVNode(" Category ");
const _hoisted_9 = {
    key: 0,
    class: "bi",
    width: "20",
    height: "20",
    fill: "#A0202E"
};
const _hoisted_10 = ["xlink:href"];
const _hoisted_11 = _createTextVNode(" Last Viewed ");
const _hoisted_12 = {
    key: 0,
    class: "bi",
    width: "20",
    height: "20",
    fill: "#A0202E"
};
const _hoisted_13 = ["xlink:href"];
const _hoisted_14 = { key: 0 };
const _hoisted_15 = _createElementVNode("td", null, [
    _createElementVNode("div", {
        class: "spinner-border spinner-border-sm",
        role: "status"
    }, [
        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
    ])
], -1);
const _hoisted_16 = [
    _hoisted_15
];
const _hoisted_17 = ["onClick"];
const _hoisted_18 = {
    class: "bi",
    fill: "#A0202E"
};
const _hoisted_19 = ["xlink:href"];
const _hoisted_20 = ["onClick"];
const _hoisted_21 = ["onClick"];
const _hoisted_22 = ["onClick"];
const _hoisted_23 = { key: 2 };
const _hoisted_24 = _createElementVNode("td", { class: "col-1" }, null, -1);
const _hoisted_25 = _createElementVNode("td", { class: "col-7" }, " No Reports Found ", -1);
const _hoisted_26 = _createElementVNode("td", { class: "col-2" }, null, -1);
const _hoisted_27 = _createElementVNode("td", { class: "col-2" }, null, -1);
const _hoisted_28 = [
    _hoisted_24,
    _hoisted_25,
    _hoisted_26,
    _hoisted_27
];
import { ref, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";
import $cookies from "vue-cookies";
import reportPageService from "@/services/application/reportPageService";
import { useApplicationStore } from "@/stores/applicationStore";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
export default _defineComponent({
    __name: 'ReportList',
    emits: ["reportOpened", "collapseSidebar"],
    setup(__props, { expose, emit }) {
        const _applicationStore = useApplicationStore();
        const reportPageSvc = new reportPageService();
        const reportPages = ref(new Array());
        const unfilteredArray = ref(new Array());
        const recentReports = ref(new Array());
        const loading = ref(true);
        const sortColumn = ref("");
        const sortAscending = ref(true);
        const router = useRouter();
        watchEffect(() => {
            if (_applicationStore.reportCategories) {
                getRecentReports();
                getReportPages();
            }
        });
        watch(() => _applicationStore.reportCategories, () => {
            getRecentReports();
            getReportPages();
        }, { deep: true });
        expose({ updateCookie, searchFilter });
        function updateCookie(newReportCookie) {
            getRecentReports();
            let newReport = reportPages.value.find(report => report.displayName === newReportCookie.displayName && report.category === newReportCookie.category);
            if (!newReport)
                return;
            newReport.lastViewed = new Date();
            const updatedReports = recentReports.value.map(report => report.reportPageId === newReport?.reportPageId ? newReport : report);
            if (!recentReports.value.some(report => report.reportPageId === newReport?.reportPageId)) {
                updatedReports.push(newReport);
            }
            $cookies.set("recentReports", updatedReports, "30d");
            emit("reportOpened");
        }
        function getRecentReports() {
            recentReports.value = $cookies.get("recentReports") || new Array();
            sortRecentReports();
            if (recentReports.value && recentReports.value.length > 10) {
                recentReports.value = recentReports.value.slice(0, 10);
            }
        }
        function sortRecentReports() {
            recentReports.value?.sort((a, b) => {
                const dateA = a.lastViewed ? new Date(a.lastViewed) : new Date(0);
                const dateB = b.lastViewed ? new Date(b.lastViewed) : new Date(0);
                return dateB.getTime() - dateA.getTime();
            });
        }
        function getReportPages() {
            reportPageSvc.getReportPages()
                .then((result) => {
                result = result.filter(rp => _applicationStore.reportCategories?.some(rc => rc.reportCategoryName === rp.category));
                reportPages.value = result.map(page => {
                    const matchingReport = recentReports.value.find(report => report.category === page.category &&
                        report.displayName === page.displayName &&
                        report.lastViewed);
                    if (matchingReport?.lastViewed) {
                        page.lastViewed = matchingReport.lastViewed;
                    }
                    return page;
                });
                sortTable("displayName");
                unfilteredArray.value = reportPages.value;
                loading.value = false;
            })
                .catch(reason => console.log(reason));
        }
        function searchFilter(searchInput) {
            const searchString = String(searchInput).toLowerCase().trim();
            reportPages.value = unfilteredArray.value.filter(a => a.displayName.toLowerCase().includes(searchString) ||
                a.category.toLowerCase().includes(searchString) ||
                a.tags?.toLowerCase().includes(searchString));
        }
        function sortTable(column) {
            if (sortColumn.value === column) {
                sortAscending.value = !sortAscending.value;
            }
            else {
                sortColumn.value = column;
                sortAscending.value = true;
            }
            reportPages.value.sort((a, b) => {
                let aValue = a[sortColumn.value];
                let bValue = b[sortColumn.value];
                if (!aValue) {
                    return 1;
                }
                if (!bValue) {
                    return -1;
                }
                if (aValue === bValue) {
                    return 0;
                }
                if (sortColumn.value === "lastViewed") {
                    aValue = new Date(aValue);
                    bValue = new Date(bValue);
                }
                const result = aValue > bValue ? 1 : -1;
                return sortAscending.value ? result : -result;
            });
        }
        function openReportPage(page) {
            getRecentReports();
            page.lastViewed = new Date();
            const updatedReports = recentReports.value.map(report => report.reportPageId === page.reportPageId ? page : report);
            if (!recentReports.value.some(report => report.reportPageId === page.reportPageId)) {
                updatedReports.push(page);
            }
            $cookies.set("recentReports", updatedReports, "30d");
            emit("reportOpened");
            emit("collapseSidebar");
            router.push({ path: page.category, query: { pageName: page.reportPageName } });
        }
        function formatLastOpenedTime(page) {
            const now = new Date();
            const lastViewedDate = page.lastViewed ? new Date(page.lastViewed) : new Date(0);
            const diffInMs = now.getTime() - lastViewedDate.getTime();
            const seconds = Math.floor(diffInMs / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            const weeks = Math.floor(days / 7);
            const months = Math.floor(days / 30);
            const years = Math.floor(days / 365);
            if (years > 10)
                return "Never";
            if (years > 0) {
                return years === 1 ? "1 year ago" : `${years} years ago`;
            }
            else if (months > 0) {
                return months === 1 ? "1 month ago" : `${months} months ago`;
            }
            else if (weeks > 0) {
                return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
            }
            else if (days > 0) {
                return days === 1 ? "1 day ago" : `${days} days ago`;
            }
            else if (hours > 0) {
                return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
            }
            else if (minutes > 0) {
                return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
            }
            else {
                return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
            }
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _hoisted_1,
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("table", _hoisted_3, [
                        _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                                _hoisted_4,
                                _createElementVNode("th", {
                                    class: "col-7",
                                    onClick: _cache[0] || (_cache[0] = ($event) => (sortTable('displayName')))
                                }, [
                                    _hoisted_5,
                                    (sortColumn.value === 'displayName')
                                        ? (_openBlock(), _createElementBlock("svg", _hoisted_6, [
                                            _createElementVNode("use", {
                                                "xlink:href": `${_unref(bootstrapIcons)}#${sortAscending.value ? 'sort-alpha-down' : 'sort-alpha-down-alt'}`
                                            }, null, 8, _hoisted_7)
                                        ]))
                                        : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("th", {
                                    class: "col-2",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (sortTable('category')))
                                }, [
                                    _hoisted_8,
                                    (sortColumn.value === 'category')
                                        ? (_openBlock(), _createElementBlock("svg", _hoisted_9, [
                                            _createElementVNode("use", {
                                                "xlink:href": `${_unref(bootstrapIcons)}#${sortAscending.value ? 'sort-alpha-down' : 'sort-alpha-down-alt'}`
                                            }, null, 8, _hoisted_10)
                                        ]))
                                        : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("th", {
                                    class: "col-2 hideOnMobile",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (sortTable('lastViewed')))
                                }, [
                                    _hoisted_11,
                                    (sortColumn.value === 'lastViewed')
                                        ? (_openBlock(), _createElementBlock("svg", _hoisted_12, [
                                            _createElementVNode("use", {
                                                "xlink:href": `${_unref(bootstrapIcons)}#${sortAscending.value ? 'sort-down' : 'sort-down-alt'}`
                                            }, null, 8, _hoisted_13)
                                        ]))
                                        : _createCommentVNode("", true)
                                ])
                            ])
                        ]),
                        _createElementVNode("tbody", null, [
                            (loading.value)
                                ? (_openBlock(), _createElementBlock("tr", _hoisted_14, _hoisted_16))
                                : (reportPages.value.length > 0)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(reportPages.value, (page) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                            key: page.reportPageId,
                                            style: { "line-height": "35px" }
                                        }, [
                                            _createElementVNode("td", {
                                                class: "col-1 text-center",
                                                onClick: ($event) => (openReportPage(page))
                                            }, [
                                                (_openBlock(), _createElementBlock("svg", _hoisted_18, [
                                                    _createElementVNode("use", {
                                                        "xlink:href": `${_unref(bootstrapIcons)}#${page.icon}`
                                                    }, null, 8, _hoisted_19)
                                                ]))
                                            ], 8, _hoisted_17),
                                            _createElementVNode("td", {
                                                class: "col-7",
                                                onClick: ($event) => (openReportPage(page))
                                            }, _toDisplayString(page.displayName), 9, _hoisted_20),
                                            _createElementVNode("td", {
                                                class: "col-2",
                                                onClick: ($event) => (openReportPage(page))
                                            }, _toDisplayString(page.category), 9, _hoisted_21),
                                            _createElementVNode("td", {
                                                class: "col-2 hideOnMobile",
                                                onClick: ($event) => (openReportPage(page))
                                            }, _toDisplayString(formatLastOpenedTime(page)), 9, _hoisted_22)
                                        ]));
                                    }), 128))
                                    : (_openBlock(), _createElementBlock("tr", _hoisted_23, _hoisted_28))
                        ])
                    ])
                ])
            ], 64));
        };
    }
});
