import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, unref as _unref, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = _createElementVNode("div", { class: "fw-semibold fs-5 my-3 mx-5 text-center" }, " Recommended Reports ", -1);
const _hoisted_2 = {
    key: 0,
    class: "spinner-border spinner-border-sm",
    role: "status"
};
const _hoisted_3 = _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1);
const _hoisted_4 = [
    _hoisted_3
];
const _hoisted_5 = {
    key: 1,
    class: "reports-container"
};
const _hoisted_6 = ["fill", "onClick"];
const _hoisted_7 = ["xlink:href"];
import { ref, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";
import recommendedReportService from "@/services/application/recommendedReportService";
import { useApplicationStore } from "@/stores/applicationStore";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
export default _defineComponent({
    __name: 'RecommendedReports',
    emits: ["collapseSidebar"],
    setup(__props, { emit }) {
        const recommendedReportSvc = new recommendedReportService();
        const recommendedReports = ref(new Array());
        const loading = ref(true);
        const router = useRouter();
        const _applicationStore = useApplicationStore();
        watchEffect(() => {
            if (_applicationStore.reportCategories) {
                getRecommendedReports();
            }
        });
        watch(() => _applicationStore.reportCategories, () => {
            getRecommendedReports();
        }, { deep: true });
        function getRecommendedReports() {
            recommendedReportSvc.getRecommendedReports()
                .then((result) => {
                result = result.filter(rr => _applicationStore.reportCategories?.some(rc => rc.reportCategoryName === rr.category));
                recommendedReports.value = result;
                loading.value = false;
            })
                .catch(reason => console.log(reason));
        }
        function openReportPage(page) {
            emit("collapseSidebar");
            router.push({ path: page.category, query: { pageName: page.pageName } });
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _hoisted_1,
                _createElementVNode("div", null, [
                    (loading.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _hoisted_4))
                        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recommendedReports.value, (report) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: report.id,
                                    class: "report-item text-center"
                                }, [
                                    (_openBlock(), _createElementBlock("svg", {
                                        class: "bi",
                                        fill: report.iconColour,
                                        onClick: ($event) => (openReportPage(report))
                                    }, [
                                        _createElementVNode("use", {
                                            "xlink:href": `${_unref(bootstrapIcons)}#${report.iconName}`
                                        }, null, 8, _hoisted_7)
                                    ], 8, _hoisted_6)),
                                    _createElementVNode("h5", null, _toDisplayString(report.displayName), 1)
                                ]));
                            }), 128))
                        ]))
                ])
            ], 64));
        };
    }
});
