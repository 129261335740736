<template>
  <div class="container py-4">
    <div class="p-5 mb-4 bg-light rounded-3 text-center">
      <h1 class="display-5 fw-bold">
        Access Denied
      </h1>
      <p class="col-md fs-4">
        You are not authorised to access this page.
      </p>
    </div>
  </div>
</template>
