export default class userLocation {
    regionId;
    areaId;
    allLocations;
    constructor(regionId = 0, areaId = 0, allLocations = false) {
        this.regionId = regionId;
        this.areaId = areaId;
        this.allLocations = allLocations;
    }
}
