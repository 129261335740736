import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
import _imports_0 from '@/assets/images/bootstrapIcons';
const _hoisted_1 = _imports_0 + '#geo';
const _hoisted_2 = _imports_0 + '#x-square-fill';
const _hoisted_3 = { class: "filtermenu pe-3 mx-2 mb-2" };
const _hoisted_4 = { class: "input-group input-group-sm" };
const _hoisted_5 = _createElementVNode("div", {
    class: "input-group-prepend rounded-0",
    style: { "min-width": "36px" }
}, [
    _createElementVNode("div", { class: "input-group-text prependLabel p-1" }, [
        _createElementVNode("svg", {
            class: "ms-1 rounded-0",
            width: "18",
            height: "22",
            fill: "currentColor"
        }, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
        ])
    ])
], -1);
const _hoisted_6 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_7 = { class: "filteritem p-0" };
const _hoisted_8 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_9 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_10 = [
    _hoisted_9
];
const _hoisted_11 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_12 = { class: "filteritem p-0" };
const _hoisted_13 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_14 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_15 = [
    _hoisted_14
];
const _hoisted_16 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_17 = { class: "filteritem p-0" };
const _hoisted_18 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_19 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_20 = [
    _hoisted_19
];
const _hoisted_21 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_22 = { class: "filteritem p-0" };
const _hoisted_23 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_24 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_25 = [
    _hoisted_24
];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dropdown = _resolveComponent("Dropdown");
    return (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
            _hoisted_5,
            _createElementVNode("div", {
                class: _normalizeClass(['filteritem filterlabel p-1 px-3']),
                onClick: _cache[0] || (_cache[0] =
                    (...args) => ($options.toggleLocationSelection && $options.toggleLocationSelection(...args)))
            }, _toDisplayString($data.location.currentLocationLabel), 1)
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("div", _hoisted_7, [
                ($data.location.filteredTradingStatuses)
                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                        ref: "tradingStatusInput",
                        key: "tradingStatusInput",
                        options: $data.location.filteredTradingStatuses,
                        disabled: false,
                        "default-item": $data.location.currentTradingStatus,
                        styling: $options.tradingStatusIsActive,
                        name: "new-trading-status",
                        "max-item": $options.tradingStatusCount,
                        "input-value": $options.locationTradingStatus,
                        onSelected: $options.getTradingStatusSelection
                    }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"]))
                    : _createCommentVNode("", true)
            ], 512), [
                [_vShow, $data.showLocationBreadcrumb]
            ]),
            ($data.showLocationBreadcrumb && ($data.location.currentTradingStatus != $data.location.filteredTradingStatuses[1].name))
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[1] || (_cache[1] = ($event) => ($options.getTradingStatusSelection($data.location.TradingStatuses[1])))
                    }, _hoisted_10))
                ]))
                : _createCommentVNode("", true)
        ], 512), [
            [_vShow, $data.showTradingStatusBreadcrumb]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("div", _hoisted_12, [
                ($data.location.searchableRegions)
                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                        ref: "regionInput",
                        key: "regionInput",
                        options: $data.location.searchableRegions,
                        disabled: false,
                        "default-item": $data.location.currentRegion,
                        styling: $options.regionIsActive,
                        name: "new-region",
                        "max-item": $options.regionCount,
                        "input-value": $options.locationRegion,
                        onSelected: $options.getRegionSelection
                    }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"]))
                    : _createCommentVNode("", true)
            ], 512), [
                [_vShow, $data.showLocationBreadcrumb]
            ]),
            ($data.showLocationBreadcrumb && ($data.location.currentRegion != $data.location.filteredRegions[0].name))
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[2] || (_cache[2] = ($event) => ($options.getRegionSelection($data.location.Regions[0])))
                    }, _hoisted_15))
                ]))
                : _createCommentVNode("", true)
        ], 512), [
            [_vShow, $data.showRegionBreadcrumb]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_16, [
            _withDirectives(_createElementVNode("div", _hoisted_17, [
                ($data.location.searchableAreas)
                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                        ref: "areaInput",
                        key: "areaInput",
                        options: $data.location.searchableAreas,
                        disabled: false,
                        "default-item": $data.location.currentArea,
                        styling: $options.areaIsActive,
                        name: "new-area",
                        "max-item": $options.areaCount,
                        "input-value": $options.locationArea,
                        onSelected: $options.getAreaSelection
                    }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"]))
                    : _createCommentVNode("", true)
            ], 512), [
                [_vShow, $data.showLocationBreadcrumb]
            ]),
            ($data.showLocationBreadcrumb && ($data.location.currentArea != $data.location.filteredAreas[0].name))
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[3] || (_cache[3] = ($event) => ($options.getAreaSelection($data.location.Areas[0])))
                    }, _hoisted_20))
                ]))
                : _createCommentVNode("", true)
        ], 512), [
            [_vShow, $data.showAreaBreadcrumb]
        ]),
        _createElementVNode("div", _hoisted_21, [
            _withDirectives(_createElementVNode("div", _hoisted_22, [
                ($data.location.searchableStores)
                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                        ref: "storeInput",
                        key: "storeInput",
                        options: $data.location.searchableStores,
                        disabled: false,
                        "default-item": $data.location.currentStore,
                        styling: $options.storeIsActive,
                        name: "new-store",
                        "max-item": $options.storeCount,
                        "input-value": $options.locationStore,
                        onSelected: $options.getStoreSelection
                    }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"]))
                    : _createCommentVNode("", true)
            ], 512), [
                [_vShow, $data.showLocationBreadcrumb]
            ]),
            ($data.showLocationBreadcrumb && ($data.location.currentStore != $data.location.filteredStores[0].name))
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[4] || (_cache[4] = ($event) => ($options.getStoreSelection($data.location.Stores[0])))
                    }, _hoisted_25))
                ]))
                : _createCommentVNode("", true)
        ])
    ]));
}
