import { defineStore } from "pinia";
export const useApplicationStore = defineStore({
    id: "application",
    state: () => ({
        breadcrumbPath: undefined,
        menuData: undefined,
        userDepartmentHierarchy: undefined,
        reportCategories: undefined,
        userLocation: undefined
    }),
    getters: {
        applicationMenu: (state) => state.menuData,
        breadcrumbs: (state) => state.breadcrumbPath,
        userDepartmentName: (state) => state.userDepartmentHierarchy?.departmentName,
        reportCategoryNames: (state) => state.reportCategories?.map(rc => rc.reportCategoryName),
        userLocations: (state) => state.userLocation
    },
    actions: {
        addUserLocation(newLocation) {
            if (!this.userLocation) {
                this.userLocation = [];
            }
            this.userLocation.push(newLocation);
        }
    }
});
