import { apiFactory } from "@/api/factories/apiFactory";
import { useApplicationStore } from "@/stores/applicationStore";
import routerIndex from "@/router/index";
import userLocation from "@/models/application/userLocation";
class applicationService {
    _applicationApi = apiFactory.useProfileApi();
    _applicationStore = useApplicationStore();
    _userApi = apiFactory.useUserApi();
    async initialiseApplication() {
        let allMenuItems = [
            {
                label: "Home",
                menuName: "Home",
                routeName: "Home",
                icon: "house"
            },
            {
                label: "KPIs",
                menuName: "KPIs",
                routeName: "KPIs",
                icon: "speedometer"
            },
            {
                label: "Sales",
                menuName: "SalesRoute",
                routeName: "SalesRoute",
                icon: "cart"
            },
            {
                label: "Costs",
                menuName: "CostsRoute",
                routeName: "CostsRoute",
                icon: "graph-down-arrow"
            },
            {
                label: "Inventory",
                menuName: "InventoryRoute",
                routeName: "InventoryRoute",
                icon: "box-seam"
            },
            {
                label: "People",
                menuName: "PeopleRoute",
                routeName: "PeopleRoute",
                icon: "people"
            },
            {
                label: "Fleet",
                menuName: "FleetRoute",
                routeName: "FleetRoute",
                icon: "truck"
            },
            {
                label: "Admin",
                menuName: "AdminRoute",
                icon: "shield-lock",
                children: [
                    {
                        label: "Notifications",
                        menuName: "Notifications",
                        routeName: "Maintain Notifications",
                        icon: "bell"
                    }
                ]
            }
        ];
        try {
            const profile = (await this._applicationApi.getProfile()).response;
            const user = await this._userApi.getUser();
            await this.getUserDepartmentHierarchy(user.nexusUserId);
            await this.getReportCategories(this._applicationStore.userDepartmentHierarchy);
            await this.getManualOverrides(user.nexusUserId);
            const userLoc = await this.getUserLocation(user.nexusUserId) ?? new userLocation();
            const locationOverrides = await this.getLocationOverrides(user.nexusUserId);
            const allLocations = [userLoc, ...locationOverrides];
            await allLocations.forEach(location => this._applicationStore.addUserLocation(location));
            const routes = await routerIndex.options.routes || [];
            allMenuItems = await allMenuItems.filter(menuItem => {
                const route = routes.find(route => route.name == menuItem.menuName);
                const meetsPolicy = user?.meetsPolicies(route?.meta?.requiredPolicy);
                return meetsPolicy;
            });
            allMenuItems = await allMenuItems.filter(m => this._applicationStore.reportCategories?.some((category) => category.reportCategoryName === m.label)
                || m.menuName === "Home");
            this._applicationStore.menuData = {
                menuItems: allMenuItems,
                profileDetails: profile
            };
        }
        catch (error) {
            console.error(error, "Error");
        }
    }
    updateBreadcrumbs(router) {
        if (router.currentRoute.value.meta.parentRouteName == undefined && router.currentRoute.value.name != "home") {
            this._applicationStore.breadcrumbPath = undefined;
            return;
        }
        const breadcrumbPath = this.buildBreadcrumbs(router, router.currentRoute.value.name?.toString());
        this._applicationStore.breadcrumbPath = breadcrumbPath;
        return;
    }
    buildBreadcrumbs(router, routeName) {
        const route = router.options.routes.find(item => item.name == routeName);
        const crumb = {
            label: route?.meta?.title,
            routeName: route?.name
        };
        let path = new Array(crumb);
        if (route?.meta?.parentRouteName != undefined) {
            path = [...this.buildBreadcrumbs(router, route?.meta?.parentRouteName), ...path];
        }
        return path;
    }
    async getUserDepartmentHierarchy(nexusUserId) {
        this._applicationStore.userDepartmentHierarchy = await this._userApi.getUserDepartmentHierarchy(nexusUserId) ??
            { teamId: 0, teamName: "Default Team", departmentId: 0, departmentName: "Default Department", functionId: 0, functionName: "Default Function" };
        return this._applicationStore.userDepartmentHierarchy;
    }
    async getReportCategories(userDepartmentHierarchy) {
        this._applicationStore.reportCategories = await this._userApi.getReportCategories(userDepartmentHierarchy);
        return this._applicationStore.reportCategories;
    }
    async getManualOverrides(nexusUserId) {
        const manualOverrides = await this._userApi.getManualOverrides(nexusUserId);
        manualOverrides ? this._applicationStore.reportCategories?.push(...manualOverrides) : null;
        return manualOverrides;
    }
    async getUserLocation(nexusUserId) {
        return await this._userApi.getUserLocation(nexusUserId);
    }
    async getLocationOverrides(nexusUserId) {
        return await this._userApi.getLocationOverrides(nexusUserId);
    }
}
export default applicationService;
