import { watch } from "vue";
import user from "@/models/application/User";
import locationService from "@/services/application/locationService";
import Dropdown from "@/components/root/dropdown.vue";
import { useAuthStore } from "@/stores/authStore";
import { useApplicationStore } from "@/stores/applicationStore";
const locationSvc = new locationService();
const regionNumber = 0;
const areaNumber = 0;
let userDetails = new user(undefined, undefined, false, undefined, 0, undefined);
export default {
    name: "LocationFilter",
    components: {
        Dropdown
    },
    props: {
        reportId: String,
        region: String,
        area: String,
        store: String
    },
    emits: {
        locationFilterReset: null,
        location: null
    },
    data() {
        return {
            showLocationBreadcrumb: false,
            showTradingStatusBreadcrumb: true,
            showRegionBreadcrumb: true,
            showAreaBreadcrumb: true,
            userLocations: [],
            currentUserLocation: {},
            location: {
                hierarchyLevel: "Company", currentLocationLabel: "All Locations",
                TradingStatuses: [], filteredTradingStatuses: [], defaultTradingStatus: "Trading Stores", currentTradingStatus: "Trading Stores",
                Regions: [], filteredRegions: [], defaultRegion: "All Regions", currentRegion: "All Regions", searchableRegions: [],
                Areas: [], filteredAreas: [], defaultArea: "All Areas", currentArea: "All Areas", searchableAreas: [],
                Stores: [], filteredStores: [], defaultStore: "All Stores", currentStore: "All Stores", searchableStores: []
            }
        };
    },
    computed: {
        tradingStatusIsActive() {
            switch (this.location.hierarchyLevel) {
                case "Company":
                    return "Inactive";
                case "Trading Status":
                    return "Active";
                default:
                    return "None";
            }
        },
        regionIsActive() {
            switch (this.location.hierarchyLevel) {
                case "Company":
                case "Trading Status":
                    return "Inactive";
                case "Region":
                    return "Active";
                default:
                    return "None";
            }
        },
        areaIsActive() {
            switch (this.location.hierarchyLevel) {
                case "Company":
                case "Region":
                case "Trading Status":
                    return "Inactive";
                case "Area":
                    return "Active";
                default:
                    return "None";
            }
        },
        storeIsActive() {
            switch (this.location.hierarchyLevel) {
                case "Store":
                    return "Active";
                default:
                    return "Inactive";
            }
        },
        storeCount() {
            if (typeof (this.location.filteredStores) == "undefined")
                return 500;
            return this.location.filteredStores.length;
        },
        areaCount() {
            if (typeof (this.location.filteredAreas) == "undefined")
                return 500;
            return this.location.filteredAreas.length;
        },
        regionCount() {
            if (typeof (this.location.filteredRegions) == "undefined")
                return 500;
            return this.location.filteredRegions.length;
        },
        tradingStatusCount() {
            if (typeof (this.location.filteredTradingStatuses) == "undefined")
                return 500;
            return this.location.filteredTradingStatuses.length;
        },
        locationStore: {
            get() {
                return this.location.currentStore;
            },
            async set(val) {
                await this.updateHierarchy("Store", val);
            }
        },
        locationArea: {
            get() {
                return this.location.currentArea;
            },
            async set(val) {
                await this.updateHierarchy("Area", val);
            }
        },
        locationRegion: {
            get() {
                return this.location.currentRegion;
            },
            async set(val) {
                if (val === "All Regions") {
                    await this.updateHierarchy("Company", "All Regions");
                }
                else {
                    await this.updateHierarchy("Region", val);
                }
            }
        },
        locationTradingStatus: {
            get() {
                return this.location.currentTradingStatus;
            },
            async set(val) {
                if (val === "All Trading Statuses") {
                    await this.updateHierarchy("All Trading Statuses", "All Trading Statuses");
                }
                else {
                    await this.updateHierarchy("Trading Status", val);
                }
            }
        }
    },
    watch: {
        tradingStatus(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.location.TradingStatuses.find(t => t.name == newValue);
            this.getTradingStatusSelection(selection);
        },
        region(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.location.Regions.find(r => r.name == newValue);
            this.getRegionSelection(selection);
        },
        area(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.location.Areas.find(a => a.name == newValue);
            this.getAreaSelection(selection);
        },
        store(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.location.Stores.find(s => s.name == newValue);
            this.getStoreSelection(selection);
        }
    },
    async mounted() {
        watch(() => useApplicationStore().userLocation, (newVal) => {
            if (newVal) {
                this.userLocations = newVal;
                this.currentUserLocation = newVal[0];
                this.getUserDetails();
                this.getLocationDropdownData();
            }
        }, { immediate: true });
        window.addEventListener("click", this.handleBodyClick);
        window.addEventListener("blur", this.handleBodyClick);
    },
    beforeUnmount() {
        window.removeEventListener("click", this.handleBodyClick);
        window.removeEventListener("blur", this.handleBodyClick);
    },
    methods: {
        async getUserDetails() {
            const authStore = useAuthStore();
            userDetails = authStore.userDetails;
        },
        handleBodyClick(event) {
            try {
                let clickOutsideComponent = !this.$el.contains(event.target);
                if (clickOutsideComponent) {
                    this.showLocationBreadcrumb = false;
                }
            }
            catch {
                this.showLocationBreadcrumb = false;
            }
        },
        async getLocationDropdownData() {
            let gotTradingStatuses = this.getTradingStatuses();
            let gotRegions = this.getRegions();
            let gotAreas = this.getAreas(regionNumber);
            let gotStores = this.getStores(areaNumber);
            await Promise.all([gotTradingStatuses, gotRegions, gotAreas, gotStores]);
            if (userDetails.userType == "Store") {
                await this.filterLocationsForStores();
            }
            else if (this.userLocations.some(loc => loc.areaId != null)) {
                await this.filterLocationsAtAreaLevel();
            }
            else if (this.userLocations.some(loc => loc.allLocations == true)) {
                return;
            }
        },
        async filterLocationsAtAreaLevel() {
            const userRegionIds = this.userLocations.map(loc => loc.regionId);
            this.location.Regions = this.location.Regions.filter(region => userRegionIds.includes(region.id));
            this.location.filteredRegions = this.location.Regions;
            this.location.searchableRegions = this.location.Regions;
            this.location.Areas = this.location.Areas.filter(area => userRegionIds.includes(area.parentId) || area.id == 0);
            this.location.filteredAreas = this.location.Areas;
            this.location.searchableAreas = this.location.Areas;
            this.location.Stores = this.location.Stores.filter(store => userRegionIds.includes(store.parent2Id) || store.id == 0);
            this.location.filteredStores = this.location.Stores;
            this.location.searchableStores = this.location.Stores;
            this.showTradingStatusBreadcrumb = false;
            this.showRegionBreadcrumb = this.location.Regions.length > 1;
            const currentUserAreaName = this.location.Areas.find(r => r.id === this.currentUserLocation.areaId)?.name;
            this.location.currentRegion = this.location.filteredRegions[0].name;
            this.location.currentArea = currentUserAreaName;
            this.location.currentLocationLabel = currentUserAreaName;
            this.location.hierarchyLevel = "Area";
            this.updateHierarchy("Area", this.location.currentArea);
        },
        async filterLocationsForStores() {
            const userRegionNumber = this.location.Stores.find(store => store.id == userDetails.locationId)?.parent2Id;
            const userAreaNumber = this.location.Stores.find(store => store.id == userDetails.locationId)?.parentId;
            this.location.Regions = this.location.Regions.filter(region => region.id == userRegionNumber);
            this.location.filteredRegions = this.location.Regions;
            this.location.searchableRegions = this.location.Regions;
            this.location.Areas = this.location.Areas.filter(area => area.id == userAreaNumber);
            this.location.filteredAreas = this.location.Areas;
            this.location.searchableAreas = this.location.Areas;
            this.location.Stores = this.location.Stores.filter(store => store.parentId == userAreaNumber || store.id == 0);
            this.location.filteredStores = this.location.Stores;
            this.location.searchableStores = this.location.Stores;
            this.showTradingStatusBreadcrumb = false;
            this.showRegionBreadcrumb = false;
            this.showAreaBreadcrumb = false;
            this.location.currentRegion = this.location.filteredRegions[0].name;
            this.location.currentArea = this.location.filteredAreas[0].name;
            this.location.currentStore = this.location.filteredStores.find(store => store.id == userDetails.locationId).name;
            this.location.currentLocationLabel = this.location.currentStore;
            this.location.hierarchyLevel = "Store";
            this.updateHierarchy("Store", this.location.currentStore);
        },
        async getTradingStatuses() {
            const result = await locationSvc.getTradingStatuses();
            this.location.TradingStatuses = result;
            this.location.filteredTradingStatuses = result;
        },
        async getRegions() {
            const result = await locationSvc.getRegions();
            this.location.Regions = result;
            this.location.filteredRegions = result.filter(r => r.parentName == this.location.defaultTradingStatus || r.id == 0);
            this.location.searchableRegions = result.filter(r => r.parentName == this.location.defaultTradingStatus || r.id == 0);
        },
        async getAreas(regionId) {
            const result = await locationSvc.getAreas(regionId);
            this.location.Areas = result;
            this.location.filteredAreas = result.filter(a => a.parent2Name == this.location.defaultTradingStatus || a.id == 0);
            this.location.searchableAreas = result.filter(a => a.parent2Name == this.location.defaultTradingStatus || a.id == 0);
        },
        async getStores(areaId) {
            const result = await locationSvc.getStores(areaId);
            this.location.Stores = result;
            this.location.filteredStores = result.filter(s => s.parent3Name == this.location.defaultTradingStatus || s.id == 0);
            this.location.searchableStores = result.filter(s => s.parent3Name == this.location.defaultTradingStatus || s.id == 0);
        },
        toggleLocationSelection() {
            this.showLocationBreadcrumb = !this.showLocationBreadcrumb;
        },
        getTradingStatusSelection(selection) {
            if (selection.name == this.location.currentTradingStatus)
                return;
            this.getFilterSelection(selection, "Trading Status");
        },
        getRegionSelection(selection) {
            if (selection.name == this.location.currentRegion)
                return;
            this.getFilterSelection(selection, "Region");
        },
        getAreaSelection(selection) {
            if (selection.name == this.location.currentArea)
                return;
            this.getFilterSelection(selection, "Area");
        },
        getStoreSelection(selection) {
            if (selection.name == this.location.currentStore)
                return;
            this.getFilterSelection(selection, "Store");
        },
        async updateHierarchy(level, value) {
            if (level == "Company") {
                this.location.hierarchyLevel = "Company";
                this.location.currentLocationLabel = "All Locations";
                this.location.currentTradingStatus = this.location.defaultTradingStatus;
                this.location.currentRegion = this.location.defaultRegion;
                this.location.currentArea = this.location.defaultArea;
                this.location.currentStore = this.location.defaultStore;
                this.location.filteredRegions = await this.location.Regions.filter(loc => loc.parentId == 1 || loc.id == 0);
                this.location.searchableRegions = [...new Set([...this.location.filteredRegions, ...this.location.Regions])];
                this.location.filteredAreas = await this.location.Areas.filter(loc => loc.parent2Id == 1 || loc.id == 0);
                this.location.searchableAreas = [...new Set([...this.location.filteredAreas, ...this.location.Areas])];
                this.location.filteredStores = await this.location.Stores.filter(loc => loc.parent3Id == 1 || loc.id == 0);
                this.location.searchableStores = [...new Set([...this.location.filteredStores, ...this.location.Stores])];
            }
            else if (level == "All Trading Statuses") {
                this.location.hierarchyLevel = "Trading Status";
                this.location.currentLocationLabel = value;
                this.location.currentTradingStatus = value;
                this.location.currentRegion = this.location.defaultRegion;
                this.location.currentArea = this.location.defaultArea;
                this.location.currentStore = this.location.defaultStore;
                this.location.filteredTradingStatuses = this.location.TradingStatuses;
                this.location.filteredRegions = this.location.Regions;
                this.location.searchableRegions = [...new Set([...this.location.filteredRegions, ...this.location.Regions])];
                this.location.filteredAreas = this.location.Areas;
                this.location.searchableAreas = [...new Set([...this.location.filteredAreas, ...this.location.Areas])];
                this.location.filteredStores = this.location.Stores;
                this.location.searchableStores = [...new Set([...this.location.filteredStores, ...this.location.Stores])];
            }
            else if (level == "Trading Status") {
                this.location.hierarchyLevel = "Trading Status";
                this.location.currentLocationLabel = value;
                this.location.currentTradingStatus = value;
                this.location.currentRegion = this.location.defaultRegion;
                this.location.currentArea = this.location.defaultArea;
                this.location.currentStore = this.location.defaultStore;
                this.location.filteredTradingStatuses = this.location.TradingStatuses;
                this.location.filteredRegions = await this.location.Regions.filter(loc => loc.parentName == value || loc.id == 0);
                this.location.searchableRegions = [...new Set([...this.location.filteredRegions, ...this.location.Regions])];
                this.location.filteredAreas = await this.location.Areas.filter(loc => loc.parent2Name == value || loc.id == 0);
                this.location.searchableAreas = [...new Set([...this.location.filteredAreas, ...this.location.Areas])];
                this.location.filteredStores = await this.location.Stores.filter(loc => loc.parent3Name == value || loc.id == 0);
                this.location.searchableStores = [...new Set([...this.location.filteredStores, ...this.location.Stores])];
            }
            else if (level == "Region") {
                let parentTradingStatus = await this.location.Regions.find((item) => item.name == value)?.parentName;
                this.location.hierarchyLevel = "Region";
                this.location.currentLocationLabel = value;
                this.location.currentTradingStatus = parentTradingStatus;
                this.location.currentRegion = value;
                this.location.currentArea = this.location.defaultArea;
                this.location.currentStore = this.location.defaultStore;
                this.location.filteredTradingStatuses = this.location.TradingStatuses;
                this.location.filteredRegions = await this.location.Regions.filter(loc => loc.parentName == parentTradingStatus || loc.id == 0);
                this.location.searchableRegions = [...new Set([...this.location.filteredRegions, ...this.location.Regions])];
                this.location.filteredAreas = await this.location.Areas.filter(loc => loc.parentName == value || loc.id == 0);
                this.location.searchableAreas = [...new Set([...this.location.filteredAreas, ...this.location.Areas])];
                this.location.filteredStores = await this.location.Stores.filter(loc => loc.parent2Name == value || loc.id == 0);
                this.location.searchableStores = [...new Set([...this.location.filteredStores, ...this.location.Stores])];
            }
            else if (level == "Area") {
                let parentTradingStatus = await this.location.Areas.find((item) => item.name == value)?.parent2Name;
                let parentRegion = await this.location.Areas.find((item) => item.name == value)?.parentName;
                this.location.hierarchyLevel = "Area";
                this.location.currentLocationLabel = value;
                this.location.currentTradingStatus = parentTradingStatus;
                this.location.currentRegion = parentRegion;
                this.location.currentArea = value;
                this.location.currentStore = this.location.defaultStore;
                this.location.filteredTradingStatuses = this.location.TradingStatuses;
                this.location.filteredRegions = await this.location.Regions.filter(loc => loc.parentName == parentTradingStatus || loc.id == 0);
                this.location.searchableRegions = [...new Set([...this.location.filteredRegions, ...this.location.Regions])];
                this.location.filteredAreas = await this.location.Areas.filter(loc => loc.parentName == parentRegion || loc.id == 0);
                this.location.searchableAreas = [...new Set([...this.location.filteredAreas, ...this.location.Areas])];
                this.location.filteredStores = await this.location.Stores.filter(loc => loc.parentName == value || loc.id == 0);
                this.location.searchableStores = [...new Set([...this.location.filteredStores, ...this.location.Stores])];
            }
            else if (level == "Store") {
                let parentTradingStatus = await this.location.Stores.find((item) => item.name == value)?.parent3Name;
                let parentRegion = await this.location.Stores.find((item) => item.name == value)?.parent2Name;
                let parentArea = await this.location.Stores.find((item) => item.name == value)?.parentName;
                this.location.hierarchyLevel = "Store";
                this.location.currentLocationLabel = value;
                this.location.currentTradingStatus = parentTradingStatus;
                this.location.currentRegion = parentRegion;
                this.location.currentArea = parentArea;
                this.location.currentStore = value;
                this.location.filteredTradingStatuses = this.location.TradingStatuses;
                this.location.filteredRegions = await this.location.Regions.filter(loc => loc.parentName == parentTradingStatus || loc.id == 0);
                this.location.searchableRegions = [...new Set([...this.location.filteredRegions, ...this.location.Regions])];
                this.location.filteredAreas = await this.location.Areas.filter(loc => loc.parentName == parentRegion || loc.id == 0);
                this.location.searchableAreas = [...new Set([...this.location.filteredAreas, ...this.location.Areas])];
                this.location.filteredStores = await this.location.Stores.filter(loc => loc.parentName == parentArea || loc.id == 0);
                this.location.searchableStores = [...new Set([...this.location.filteredStores, ...this.location.Stores])];
            }
            this.$emit("location", { hierarchyLevel: this.location.hierarchyLevel, tradingStatus: this.location.currentTradingStatus, region: this.location.currentRegion, area: this.location.currentArea, store: this.location.currentStore });
        },
        async getFilterSelection(selection, selectionType) {
            this.showLocationBreadcrumb = false;
            if (selection.id == "0") {
                if (selectionType == "Trading Status") {
                    await (this.locationTradingStatus = selection.name);
                }
                if (selectionType == "Region") {
                    await (this.locationRegion = this.location.defaultRegion);
                }
                if (selectionType == "Area") {
                    await (this.locationRegion = this.location.currentRegion);
                }
                if (selectionType == "Store") {
                    await (this.locationArea = this.location.currentArea);
                }
                this.$emit("locationFilterReset", selectionType);
            }
            if (selection.id != "0") {
                if (selectionType == "Trading Status")
                    await (this.locationTradingStatus = selection.name);
                if (selectionType == "Region")
                    await (this.locationRegion = selection.name);
                if (selectionType == "Area")
                    await (this.locationArea = selection.name);
                if (selectionType == "Store")
                    await (this.locationStore = selection.name);
            }
        },
    },
};
